import { useState } from "react";
import { UsersListLoading } from "../../Reviews/Review/users-list/components/loading/UsersListLoading";
import { KTCardBody } from "../../../../_metronic/helpers";
import dayjs from "dayjs";
import { Pagination } from "../../../../components/Pagination";
import { Link } from "react-router-dom";
import ImageViewer from "awesome-image-viewer";
import axios from "axios";
import { toast } from "react-toastify";

const headers = [
  { title: 'Listing Name', className: 'w-200' },
  { title: 'Date', className: 'w-200' },
  { title: 'View Image', className: 'w-200' },
  { title: 'Uploaded By', className: 'w-150' },
  { title: 'Requested By', className: 'w-150' },
  { title: 'Action', className: 'w-200' },
]

export const ImageTable = ({
  images,
  isLoading,
  setLoading,
  fetchImageApprovals,
  total,
  page,
  setPage,
  pageSize,
  setPageSize,
  haveAccess,
  currentUser,
}) => {

  const PendingApproval = (type, id) => {
    if (type) {
      setLoading(true)
      axios.patch(`${process.env.REACT_APP_API_V3_URL}/listing/images/approve?asset_id=${id}`).then(res => {
        toast.success("Image Approved Successfully");
        fetchImageApprovals()
        setLoading(false)
      }).catch(err => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message)
          setLoading(false)
        }
      })
    } else {
      setLoading(true)
      axios.patch(`${process.env.REACT_APP_API_V3_URL}/listing/images/stash?asset_id=${id}`).then(res => {
        toast.success("Image Approval Rejected");
        fetchImageApprovals()
        setLoading(false)
      }).catch(err => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message)
          setLoading(false)
        }
      })
    }
  };

  return (
    <KTCardBody className='py-4'>
      <h6 style={{ color: '#7e8299', textAlign: 'right' }}>Total: {total}</h6>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map(column => (
                <th
                  key={column.title}
                  className={column.className}
                  style={{ textAlign: column.title === 'Action' ? 'center' : 'left' }}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            {images.length > 0 && (
              images.map((row, i) => (
                <tr key={row.id}>
                  <td className='w-200' style={{ minWidth: '240px' }}><Link to={`/listings/${row.asset_owner}`}>{row.owner_name || '-'}</Link></td>
                  <td className='w-200' style={{ minWidth: '160px' }}>{dayjs(row.updatedAt).format('MMMM DD YYYY')}</td>
                  <td className='w-200 pointer' style={{ minWidth: '120px' }}
                    onClick={() =>
                      new ImageViewer({
                        images: [
                          {
                            mainUrl: process.env.REACT_APP_IMAGE_URL + "1920:720:" + row.latest_file.slice(1)
                          }
                        ],
                        isZoomable: false,
                        closeOnEsc: true
                      })
                    }
                  >
                    <img src={process.env.REACT_APP_IMAGE_URL + '300:200:' + row.latest_file.slice(1)} width={'120px'} height={'80px'} />
                  </td>
                  <td className='w-200' style={{ minWidth: '150px' }}>{row.uploader_name}</td>
                  <td className='w-200' style={{ minWidth: '150px' }}>{row.requester_name || '-'}</td>
                  <td className='w-200' style={{ textAlign: 'center', display: 'grid', gap: '5px' }}
                  ><span style={{ padding: '10px', fontWeight: '700', background: '#2ecc71', borderRadius: '12px', color: '#fff', cursor: 'pointer' }}
                    onClick={() => {
                        PendingApproval(true, row.asset_id)
                    }}
                  >Approve</span>
                    <span style={{ padding: '10px', fontWeight: '700', background: '#ed2626', borderRadius: '12px', color: '#fff', cursor: 'pointer' }}
                      onClick={() => {
                        PendingApproval(false, row.asset_id)
                      }}
                    >Reject</span>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {images && !images.length > 0 &&
          <div className='d-flex text-center text-gray-600 fw-bolder fs-6 w-100 align-content-center justify-content-center mt-10'>
            No matching records found
          </div>
        }
      </div>
      <Pagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={total}
      />
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  );
};
