import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ToastStyle } from "../../../../../_metronic/helpers/utils";
import { useAuth } from "../../../auth";
import PhotoUploader from "./PhotoUploader";
import ImageViewer from "awesome-image-viewer";
import "./styles.scss";

const PhotoUploaderWrapper = ({
  listing,
  getListing,
  openPhotoUploaderModal
}) => {
  const { currentUser, haveAccess } = useAuth();
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('0,1,2,3')
  const [total, setTotal] = useState(1);
  const [images, setImages] = useState([])
  const [isLoading, setLoading] = useState(false)

  function DownloadImage(url) {
    setLoading(true)
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const blobURL = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.download = url.split("/").pop();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobURL);
        setLoading(false)
      })
      .catch(error => {
        toast.error('Error downloading image:')
        setLoading(false)
      });
  }
  
  const makeProfilePicture = (id) => {
    setLoading(true)
    axios
      .put(`${process.env.REACT_APP_API_URL}/listing/${listing?.listing_id}`, {
        avatar: id,
      })
      .then((res) => {
        setLoading(false)
        getListing();
        toast.success("Profile Picture Updated Successfully");
      })
      .catch((err) => {
        setLoading(false)
        toast.error("Error Changing Default Picture");
      });
  };

  const DeleteImage = (id, file) => {
    setLoading(true)
    if (file === listing?.avatar) {
      toast.error("Profile Picture Cannot be Deleted");
    } else {
      axios.delete(`${process.env.REACT_APP_API_V3_URL}/listing/images?asset_id=${id}`).then(res => {
        toast("Image Deleted Successfully", ToastStyle);
        getAllImages()
        setLoading(false)
      }).catch(err => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message)
          setLoading(false)
        }
      })
    }
  }
  const ApproveRequestImage = (id) => {
    setLoading(true)
    axios.patch(`${process.env.REACT_APP_API_V3_URL}/listing/images/request?asset_id=${id}`).then(res => {
      toast("Approval Request Sent", ToastStyle);
      getAllImages()
      setLoading(false)
    }).catch(err => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast.error(err.response.data.message)
        setLoading(false)
      }
    })
  }

  const getAllImages = () => {
    setLoading(true)
    axios.get(`${process.env.REACT_APP_API_V3_URL}/listing/images?listing_id=${listing.listing_id}&page=${page}&page_size=${pageSize}&status=${status}`)
      .then(res => {
        setImages(res.data.data)
        setTotal(res.data.total)
        setLoading(false)
      }).catch(err => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message)
          setLoading(false)
        }
      })
  }


  useEffect(() => {
    getAllImages()
  }, [status])


  const showImageStatus = (status) => {
    switch (status) {
      case 0:
        return {
          text: 'Pending',
          color: 'warning'
        }
      case 1:
        return {
          text: 'Approved',
          color: 'success'
        }
      case 2:
        return {
          text: 'Rejected',
          color: 'danger'
        }
      case 3:
        return {
          text: 'Pending Approval',
          color: 'info'
        }
      case -1:
        return {
          text: 'Deleted',
          color: 'danger'
        }
      default:
        return {
          text: 'Pending',
          color: 'warning'
        }
    }
  }

  return (
    <>
      <div className={`kt_body w-100 ${openPhotoUploaderModal ? 'uploader-wrapper' : 'hide-uploader-wrapper'}`}>
        {(haveAccess(currentUser, 'listing_photo_upload')) && <PhotoUploader getAllImages={getAllImages} listing={listing} />}
        {(haveAccess(currentUser, 'listing_photo_list')) && <div>
          <div className="d-flex justify-content-between align-items-center my-5" style={{ width: '90%'}}>
            <h2>Uploaded Images</h2>
            <select
              className="form-select form-select-solid fw-bolder"
              data-placeholder="Select Filter"
              data-hide-search="true"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              style={{ width: 'fit-content'}}
            >
              <option value='0,1,2,3' selected>All Images</option>
              <option value='0'>Pending Images</option>
              <option value='1'>Approved Images</option>
              <option value='3'>Pending For Approval Images</option>
              <option value='2'>Rejected Images</option>
            </select>
          </div>
          <div className="d-flex flex-wrap gap-4 justify-content-start">
            {images && !!images.length ?
              images.map((el) => (
                <div
                  key={el.asset_id}
                  className="rounded-sm position-relative img-container"
                  style={{ width: '205px', height: '200px' }}
                >
                  {(haveAccess(currentUser, 'listing_photo_approve')) && <button
                    className="download-img-btn"
                    disabled={isLoading}
                    onClick={() => DownloadImage(`${process.env.REACT_APP_IMAGE_URL}1920:720:${el.latest_file.slice(1)}`)}
                  >
                    <i className="fas fa-download" />
                  </button>
                  }
                  {(haveAccess(currentUser, 'listing_photo_request')) && el.status == 0 && <button
                    className="approve-img-btn"
                    disabled={isLoading}
                    onClick={() => ApproveRequestImage(el.asset_id)}
                  >
                    Send For Approval
                  </button>
                  }
                  {(haveAccess(currentUser, 'listing_photo_delete')) && [0, 1, 2].includes(el.status) && (el.latest_file !== listing?.avatar) && <button
                    className="remove-img-btn"
                    disabled={isLoading}
                    onClick={() => DeleteImage(el.asset_id, el.latest_file)}
                  > Delete
                  </button>
                  }
                  {el.status == 1 && el.latest_file !== listing?.avatar && <button
                    className="approve-img-btn"
                    disabled={isLoading}
                    onClick={() => makeProfilePicture(el.asset_id)}
                  >
                    <i
                      className="fas fa-star"
                      style={{ color: "#fff" }}
                    ></i> Make Profile
                  </button>
                  }
                  {[1, -1, 2, 3].includes(el.status) && <span className={`status badge badge-${showImageStatus(el.status).color} fs-6`}>{el.latest_file === listing.avatar ? 'Profile Picture' : showImageStatus(el.status).text}</span>}
                  <img
                    src={process.env.REACT_APP_IMAGE_URL + '300:200:' + el.latest_file.slice(1)}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: '6px',
                      border: el?.latest_file === listing?.avatar ? '3px solid #ffa459' : 'none',
                      cursor: 'pointer'
                    }}
                    alt="images"
                    onClick={() =>
                      new ImageViewer({
                        images: [
                          {
                            mainUrl: process.env.REACT_APP_IMAGE_URL + "1920:720:" + el.latest_file.slice(1)
                          }
                        ],
                        isZoomable: false,
                        closeOnEsc: true
                      })
                    }
                  />
                </div>
              ))
            :
            <h3 className="text-danger">No {showImageStatus(+status).text} Images added yet</h3>
            }
          </div>
        </div>
        }
      </div >
    </>
  );
};

export { PhotoUploaderWrapper };
